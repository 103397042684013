import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { NOT_ENROLLED } from '@atlassian/jira-common-util-get-experiment-group';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { softwarePresentationToolkitReportRoute } from '@atlassian/jira-router-routes-software-presentation-toolkit-report-routes';
import type { Route } from '@atlassian/react-resource-router';
import PresentationToolkitReportPage, { LazySoftwarePresentationToolkitReportPage } from './ui';

const getIsEnrolledInExperiment = () => {
	// Do not want to log exposure, because there are more conditions that will be checked when the component is rendered
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('jsw_presentation_toolkit_experiment');
	const isPassedGate = fg('jsw_presentation_toolkit_gate');
	return expConfig.get('cohort', NOT_ENROLLED) !== NOT_ENROLLED && isPassedGate;
};

export const softwarePresentationToolkitReportRouteEntry: Route = createEntry(
	softwarePresentationToolkitReportRoute,
	{
		component: componentWithCondition(
			getIsEnrolledInExperiment,
			PresentationToolkitReportPage,
			ErrorPagesNotFound,
		),
		layout: chromelessLayout,
		resources: [themePreferenceResource],
		forPaint: [LazySoftwarePresentationToolkitReportPage],
	},
);

import {
	resources as opsReportsResources,
	opsReportsPermissionResource,
} from '@atlassian/eoc-ops-reports-common/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	productsJsmGlobalOpsReportsRoute,
	productsJsmGlobalOpsReports1Route,
} from '@atlassian/jira-router-routes-products-jsm-global-ops-reports-routes';
import {
	LazyGlobalOpsReports,
	LazyOpsReportsRedirect,
	GlobalOpsReports,
	OpsReportsRedirect,
} from './ui/eoc/ops-reports';

export const productsJsmGlobalOpsReportsRouteEntry = createEntry(productsJsmGlobalOpsReportsRoute, {
	component: GlobalOpsReports,

	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...opsReportsResources,
		opsReportsPermissionResource,
	],

	forPaint: [LazyAtlassianNavigation, LazyOpsReportsRedirect],
});

export const productsJsmGlobalOpsReports1RouteEntry = createEntry(
	productsJsmGlobalOpsReports1Route,
	{
		component: OpsReportsRedirect,

		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			...opsReportsResources,
			opsReportsPermissionResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyGlobalOpsReports],
	},
);

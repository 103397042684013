import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import {
	softwareCalendarRoute,
	softwareCalendarEmbedRoute,
} from '@atlassian/jira-router-routes-software-calendar-routes';
import {
	CalendarSkeleton,
	CalendarEmbedSkeleton,
} from '@atlassian/jira-skeletons/src/ui/calendar/index.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-software-calendar/entrypoint';
import type { Route } from '@atlassian/react-resource-router';

export const softwareCalendarEmbedRouteEntry: Route = createEntry(softwareCalendarEmbedRoute, {
	skeleton: CalendarEmbedSkeleton,

	component: CalendarEmbedSkeleton,

	entryPoint() {
		if (__SERVER__) {
			return undefined;
		}
		return calendarRouteEntry;
	},

	layout: chromelessLayout,

	resources: [...getNavigationSidebarProjectResource(), themePreferenceResource],

	ufoName: 'next-gen-calendar-embed',
});

export const calendarRouteEntry = createPageEntryPoint({
	main: calendarEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const softwareCalendarRouteEntry: Route = createEntry(softwareCalendarRoute, {
	skeleton: CalendarSkeleton,

	component: CalendarSkeleton,

	entryPoint() {
		if (__SERVER__) {
			return undefined;
		}
		return calendarRouteEntry;
	},

	layout: composeLayouts(
		softwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},

	resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation],
	ufoName: 'next-gen-calendar',
});

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
	getNavigationSidebarProjectSettingsResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectsComponentsResource } from '@atlassian/jira-router-resources-project-components/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { projectSettingsComponentsRoute } from '@atlassian/jira-router-routes-project-settings-components-routes';
import SettingsComponents, { LazyProjectComponentsSettings } from './ui';

export const projectSettingsComponentsRouteEntry = createEntry(projectSettingsComponentsRoute, {
	resources: [
		...getBusinessProjectResources(),
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getNavigationSidebarProjectSettingsResource(),
		projectContextResource,
		projectsComponentsResource,
		getConsolidationStateResource(),
	],

	component: SettingsComponents,
	layout: genericProjectLayout,

	navigation: {
		horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
	},

	forPaint: [LazyAtlassianNavigation, LazyProjectComponentsSettings],
});

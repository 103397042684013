import React from 'react';
import camelCase from 'lodash/camelCase';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import {
	ROUTE_NAMES_SOFTWARE_DEPLOYMENTS,
	ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { deploymentPreconditionRelayResource } from '@atlassian/jira-router-resources-software-deployments/src/services/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	softwareDeploymentsRoute,
	softwareDeploymentsClassicRoute,
} from '@atlassian/jira-router-routes-software-deployments-routes';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import {
	classicSoftwareProjectLayout,
	softwareProjectLayoutNoOnboarding,
} from '@atlassian/jira-software-project-layout';
import type NextGenSoftwareDeploymentsType from '@atlassian/jira-spa-apps-software-deployments/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

export const LazyDeployments = lazyForPaint<typeof NextGenSoftwareDeploymentsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-deployments", jiraSpaEntry: "async-deployments" */ '@atlassian/jira-spa-apps-software-deployments'
		),
	),
);

export const DeploymentsNextGen = () => (
	<LazyPage Page={LazyDeployments} pageId="deploymentsNextGen" shouldShowSpinner />
);

export const DeploymentsClassic = () => (
	<LazyPage Page={LazyDeployments} pageId="deploymentsClassic" shouldShowSpinner />
);

export const softwareDeploymentsNextGenRouteEntry: Route = createEntry(softwareDeploymentsRoute, {
	component: componentWithCondition(
		isDevopsFeatureDisabledInFedRamp,
		ErrorPagesNotFound,
		DeploymentsNextGen,
	),

	layout: softwareProjectLayoutNoOnboarding,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		deploymentPreconditionRelayResource,
		projectContextResource,
	],

	forPaint: [LazyAtlassianNavigation, LazyDeployments],

	meta: {
		reporting: {
			id: ROUTE_NAMES_SOFTWARE_DEPLOYMENTS,
			packageName: camelCase(ROUTE_NAMES_SOFTWARE_DEPLOYMENTS),
			teamName: 'fusion-solaris',
		},
	},
});

export const softwareDeploymentsClassicRouteEntry: Route = createEntry(
	softwareDeploymentsClassicRoute,
	{
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			deploymentPreconditionRelayResource,
			projectContextResource,
		],

		component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			DeploymentsClassic,
		),

		layout: classicSoftwareProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

		forPaint: [LazyAtlassianNavigation, LazyDeployments],

		meta: {
			reporting: {
				id: ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC,
				packageName: camelCase(ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC),
				teamName: 'fusion-solaris',
			},
		},
	},
);

import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { AsyncHorizontalProjectNav as AsyncHorizontalNavCore } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import AsyncSidebarServiceDesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { createLayout, type Layout } from '@atlassian/jira-route-layout';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	archivedIssuesJswRoute,
	archivedIssuesJswCompanyRoute,
	archivedIssuesJsmRoute,
	archivedIssuesJwmRoute,
	archivedIssuesRedirectRoute,
} from '@atlassian/jira-router-routes-archives-routes';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

export const LazyArchives = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-issues-archive", jiraSpaEntry: "async-issues-archive" */ '@atlassian/jira-spa-apps-archives'
		).then(({ JiraIssuesArchive }) => JiraIssuesArchive),
	),
);

export const Archives = () => (
	<LazyPage Page={LazyArchives} pageId="spa-apps/archives" shouldShowSpinner />
);

export const archivedIssuesRedirectRouteEntry: Route = createEntry(archivedIssuesRedirectRoute, {
	component: componentWithFF('issues-archive-route_a4t84', Archives, ErrorPagesNotFound),
	layout: genericProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
	},
	resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
	forPaint: [LazyAtlassianNavigation, LazyArchives],
});

const getCommonProjectLayout = (): Layout => createLayout(genericProjectLayout);

export const archivedIssuesRouteEntries: Route[] = [
	createEntry(archivedIssuesJswRoute, {
		// Refer: softwareIssueNavigatorRoute from issue-navigator
		component: componentWithFF('issues-archive-route_a4t84', Archives, ErrorPagesNotFound),
		layout: createLayout(softwareProjectLayoutNoOnboarding),
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav, // TODO: Add entry in `src/packages/navigation-apps/horizontal-nav/horizontal-nav-software/src/ui/index.tsx`
			onlyShowHorziontalOnNav4: true,
		},
		resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
		forPaint: [LazyAtlassianNavigation, LazyArchives],
	}),
	createEntry(archivedIssuesJswCompanyRoute, {
		// Refer: projectIssueNavigatorClassicRoute from issue-navigator
		component: componentWithFF('issues-archive-route_a4t84', Archives, ErrorPagesNotFound),
		layout: getCommonProjectLayout(),
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},
		resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
		forPaint: [LazyAtlassianNavigation, LazyArchives],
	}),
	createEntry(archivedIssuesJsmRoute, {
		// Refer: servicedeskIssuesRoute from issue-navigator
		component: componentWithFF('issues-archive-route_a4t84', Archives, ErrorPagesNotFound),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServiceDesk, // src/packages/navigation-apps/sidebar/servicedesk/src/ui/menu/index.tsx
		},
		resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
		forPaint: [LazyAtlassianNavigation, LazyArchives],
	}),
	createEntry(archivedIssuesJwmRoute, {
		// Refer: projectIssueNavigatorCoreRoute from issue-navigator
		component: componentWithFF('issues-archive-route_a4t84', Archives, ErrorPagesNotFound),
		layout: getCommonProjectLayout(),
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalNavCore,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getBusinessProjectResources(),
		],
		forPaint: [LazyAtlassianNavigation, LazyArchives],
	}),
];

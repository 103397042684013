import React, { useCallback, useMemo } from 'react';
import { useThemeObserver } from '@atlaskit/tokens';
import { parseUrl } from '@atlassian/embedded-confluence';
import { useEmbeddedConfluenceSidePanel } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { embeddedPanelEntrypoint } from '../../ui/embedded-panel/entrypoint';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking';
import { useParentProduct } from '../use-parent-product';

const entryPointParams = {};
export const useEmbeddedPanelEntryPoint = () => {
	const { locale } = useIntl();
	const [state, actions] = useEmbeddedConfluenceSidePanel();
	const {
		contentType,
		confluenceData: { contentId, contentUrl, isLive, isDraft, title, mode },
		isDeferSpaceSelection,
		contentActions: { onLinkPage },
		panel: { isOpen },
		embeddedConfluenceSource,
	} = state;
	const parentProduct = useParentProduct();

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		embeddedPanelEntrypoint,
		entryPointParams,
	);

	const embeddedConfluenceSourceForPanel = `${embeddedConfluenceSource}Panel`;

	const deferSpaceToast = ff('toast_defer_space_ntufi');
	const isTemplateBrowserEnabled = ff('jira.issue-view.create-confluence-page.template-browser');
	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const themeState = useThemeObserver();

	const onContentAction = useCallback(
		(action: string, eventName: string) => {
			fireAnalyticsEvent({
				action,
				eventName,
				embeddedConfluenceSource: embeddedConfluenceSourceForPanel,
			});
		},
		[fireAnalyticsEvent, embeddedConfluenceSourceForPanel],
	);

	const onClose = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'closeEmbeddedConfluencePanel',
			embeddedConfluenceSource: embeddedConfluenceSourceForPanel,
		});
		entryPointActions.unload();

		actions.closeSidePanel();
	}, [actions, fireAnalyticsEvent, entryPointActions, embeddedConfluenceSourceForPanel]);
	const editAllowedFeatures = useMemo(
		() => [
			...(isDeferSpaceSelection ? ['publish-modal'] : []),
			...(isTemplateBrowserEnabled ? ['template-browser'] : []),
		],
		[isDeferSpaceSelection, isTemplateBrowserEnabled],
	);
	const shouldApplyEditFeatures = editAllowedFeatures.length > 0 && isDraft;

	const shouldOpenPanel = isOpen && contentUrl;

	// TODO: set different runtime Props based on contentType
	const runtimeProps = useMemo(
		() => ({
			locale,
			hasFooterLogo: false,
			isDraft,
			isLive,
			panelTitle: title,
			navigationPolicy: undefined,
			onClose,
			onContentAction,
			parentProduct,
			spaceKey: parseUrl(contentUrl)?.spaceKey,
			themeState,
			url: contentUrl,
			mode,
			embeddedConfluenceSource: embeddedConfluenceSourceForPanel,
			contentId,
			contentType,
			...(deferSpaceToast && {
				onPublish: actions.setDeferredSpaceName,
			}),
			allowedFeatures: {
				view: [
					'byline-contributors',
					'byline-extensions',
					'page-comments',
					'page-reactions',
					'inline-comments',
					'non-licensed-share',
				],
				...(shouldApplyEditFeatures && {
					edit: editAllowedFeatures,
				}),
			},
			linkConfluencePage: onLinkPage,
		}),
		[
			actions.setDeferredSpaceName,
			contentId,
			contentType,
			contentUrl,
			deferSpaceToast,
			editAllowedFeatures,
			embeddedConfluenceSourceForPanel,
			isDraft,
			isLive,
			locale,
			title,
			mode,
			onClose,
			onContentAction,
			onLinkPage,
			parentProduct,
			themeState,
			shouldApplyEditFeatures,
		],
	);

	return shouldOpenPanel
		? {
				entryPointActions,
				entryPoint: (
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="epPanelEntrypointContainer"
						packageName="@atlassian/jira-issue-create-confluence-content"
						teamName="confluence-better-together"
						runtimeProps={runtimeProps}
					/>
				),
			}
		: { ref: undefined, entryPoint: null };
};

import { resources as alertDetailResources } from '@atlassian/eoc-alert-detail/resources';
import { resources as alertListResources } from '@atlassian/eoc-alert-list/resources';
import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import {
	eocTeamAlertListRoute,
	eocTeamAlertDetailRoute,
} from '@atlassian/jira-router-routes-eoc-team-alerts-routes';
import AlertList, { LazyAlertList } from './ui/spa/eoc/alert-list';

export const eocTeamAlertListRouteEntry = createEntry(eocTeamAlertListRoute, {
	component: AlertList,
	layout: opsTeamLayout,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		...alertListResources,
		getConsolidationStateResource(),
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		onlyShowHorziontalOnNav4: true,
	},
	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyAlertList,
		LazyTeamDashboardSidebar,
	],
});

export const eocTeamAlertDetailRouteEntry = createEntry(eocTeamAlertDetailRoute, {
	component: AlertList,
	layout: opsTeamLayout,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		...alertListResources,
		...alertDetailResources,
		getConsolidationStateResource(),
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
	},
	forPaint: [LazyAtlassianNavigation, LazyAlertList, LazyTeamDashboardSidebar],
});

export const eocTeamAlertListRouteEntries = [
	eocTeamAlertListRouteEntry,
	eocTeamAlertDetailRouteEntry,
];

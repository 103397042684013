import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { componentFilterResource } from '@atlassian/jira-router-resources-service-desk-customer-service-escalations/src/services/component-filter-resource/index.tsx';
import { priorityFilterResource } from '@atlassian/jira-router-resources-service-desk-customer-service-escalations/src/services/prority-filter-resource/index.tsx';
import { escalationsRoute } from '@atlassian/jira-router-routes-servicedesk-customer-service-escalations-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import type { EscalationsAppLayout as ServicedeskCustomerServiceEscalationsType } from '@atlassian/jira-spa-apps-servicedesk-customer-service-escalations/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

const LazyServicedeskCustomerServiceEscalations = lazyForPaint<
	typeof ServicedeskCustomerServiceEscalationsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-customer-service-escalations", jiraSpaEntry: "async-servicedesk-customer-service-escalations" */ '@atlassian/jira-spa-apps-servicedesk-customer-service-escalations'
		),
	),
);

export const ServicedeskCustomerServiceEscalations = () => (
	<LazyPage
		Page={LazyServicedeskCustomerServiceEscalations}
		pageId="spa-apps/servicedesk/customer-service-escalations"
		shouldShowSpinner
	/>
);

export const customerServiceEscalationsRouteEntry = createEntry(escalationsRoute, {
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
		componentFilterResource,
		priorityFilterResource,
	],
	component: ServicedeskCustomerServiceEscalations,
	perfMetricKey: 'jsm-customer-service-escalations-component-load',
	forPaint: [LazyAtlassianNavigation, LazyServicedeskCustomerServiceEscalations],
	meta: {
		reporting: {
			id: APP_NAMES.CUSTOMER_SERVICE_ESCALATIONS,
			packageName: toPackageName(APP_NAMES.CUSTOMER_SERVICE_ESCALATIONS),
			teamName: 'boysenberry',
		},
	},
});

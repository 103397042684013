import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { settingsCmdbFieldConfigRoute } from '@atlassian/jira-router-routes-settings-cmdb-field-config-routes';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import {
	AsyncCmdbFieldConfigPage,
	LazyCMDBFieldConfigAdmin,
} from './ui/admin-pages/cmdb-field-config';

export const settingsCmdbFieldConfigRouteEntry = createEntry(settingsCmdbFieldConfigRoute, {
	ufoName: 'admin.cmdb-field-config',
	component: AsyncCmdbFieldConfigPage,
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsIssues,
	},

	resources: [...getNavigationResources(), navigationSidebarGlobalResource, workspaceResource],
	forPaint: [LazyAtlassianNavigation, LazyCMDBFieldConfigAdmin],
});

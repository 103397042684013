import camelCase from 'lodash/camelCase';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { AsyncHorizontalProjectNav as AsyncHorizontalNavCore } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import { NIN_NEW_ISSUE_NAVIGATOR } from '@atlassian/jira-capabilities/src/constants.tsx';
import { ROUTE_GROUPS_ISSUE_NAVIGATOR } from '@atlassian/jira-common-constants/src/spa-routes';
import { ff } from '@atlassian/jira-feature-flagging';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { sidebarFiltersGlobalResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/filters/index.tsx';
import { staticSidebarDefaultPINFiltersResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/project-filters/index.tsx';
import { sidebarProjectIssuesResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/project-sidebar-common/index.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async';
import { AsyncSidebarFiltersGlobal } from '@atlassian/jira-navigation-apps-sidebar-filters/src/async';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { getWillShowNav3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav3.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import AsyncSidebarServiceDesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { createLayout, type Layout } from '@atlassian/jira-route-layout';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { isNinGlobalScopeEnrolmentEnabledResource } from '@atlassian/jira-router-resources-is-nin-global-scope-enrolment-enabled';
import {
	issueNavigatorRelayResource,
	issueNavigatorFilterRelayResource,
} from '@atlassian/jira-router-resources-issue-navigator';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { queuesNavigationItems } from '@atlassian/jira-router-resources-service-desk-queues/src/services/index.tsx';
import {
	resourceWithCondition,
	resourceWithCondition2,
} from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import {
	projectIssueNavigatorRoute,
	softwareIssueNavigatorRoute,
	projectIssueNavigatorClassicRoute,
	servicedeskIssuesRoute,
	projectIssueNavigatorCoreRoute,
	globalIssueNavigatorRoute,
	globalIssueNavigator1Route,
	globalIssueNavigatorBrowseIssueRedirectRoute,
} from '@atlassian/jira-router-routes-issue-navigator-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { GlobalIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/global-app.tsx';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout';
import type { Route } from '@atlassian/react-resource-router';
import {
	GinRedirect,
	ProjectIssueNavigator,
	ServiceDeskIssueNavigator,
	LazyProjectIssueNavigator,
	LazyGlobalIssueNavigator,
	LazyServiceDeskProjectIssueNavigator,
} from './ui';
import GinBrowseIssuesRedirect from './ui/gin-browse-issue-redirect';
import PinRedirect from './ui/pin-redirect';

const common = {
	apdexIgnoreParams: ['issueKey'],
	meta: {
		capability: NIN_NEW_ISSUE_NAVIGATOR,
		reporting: {
			id: ROUTE_GROUPS_ISSUE_NAVIGATOR,
			packageName: camelCase(ROUTE_GROUPS_ISSUE_NAVIGATOR),
			teamName: 'empanada',
		},
	},
};

const getCommonProjectResources = () => [
	issueNavigatorRelayResource,
	issueNavigatorFilterRelayResource,
	...getNavigationResources(),
	...getNavigationSidebarProjectResource(),
	...getBusinessProjectResources(),
	resourceWithCondition(() => ff('nin.global-scope_aqec8'), sidebarProjectIssuesResource),
	resourceWithCondition2(
		() => ff('nin.global-scope_aqec8'),
		isNinGlobalScopeEnrolmentEnabledResource,
	),
	// this resource is also used by the nav4 sidebar for JSM project's 'issues and filters'
	// src/packages/navigation-apps/sidebar-nav4/jsm-project-menu/src/ui/project-menu/issues-and-filters/default-filters/index.tsx
	staticSidebarDefaultPINFiltersResource,
];

const getCommonProject = () => ({
	component: ProjectIssueNavigator,
	skeleton: ProjectIssueNavigatorSkeleton,
	resources: [...getCommonProjectResources()],
	afterPaint: [LazyIssueApp],
});

const getCommonProjectLayout = (): Layout => createLayout(genericProjectLayout);

const getCommonGlobal = () => ({
	component: GinRedirect,
	skeleton: GlobalIssueNavigatorSkeleton,
	layout: globalLayout,
	canTransitionIn: () => ff('nin.global-scope_aqec8'),
	resources: [
		issueNavigatorRelayResource,
		issueNavigatorFilterRelayResource,
		...getNavigationResources(),
		navigationSidebarGlobalResource,
		isNinGlobalScopeEnrolmentEnabledResource,
		resourceWithCondition2(getWillShowNav3, sidebarFiltersGlobalResource),
	],
	navigation: {
		menuId: MENU_ID.FILTERS,
		sidebar: AsyncSidebarFiltersGlobal,
	},
});

export const issueNavigatorRouteEntries: Route[] = [
	createEntry(projectIssueNavigatorRoute, {
		...getCommonProject(),
		...common,
		component: PinRedirect,
		forPaint: [LazyAtlassianNavigation, LazyProjectIssueNavigator],

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: genericProjectSidebars,
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
		},

		resources: [...getCommonProject().resources, getConsolidationStateResource()],
		layout: getCommonProjectLayout(),
	}),
	createEntry(softwareIssueNavigatorRoute, {
		...getCommonProject(),
		...common,
		forPaint: [LazyAtlassianNavigation, LazyProjectIssueNavigator],

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

		layout: createLayout(softwareProjectLayoutNoOnboarding),
	}),
	createEntry(projectIssueNavigatorClassicRoute, {
		...getCommonProject(),
		...common,
		forPaint: [LazyAtlassianNavigation, LazyProjectIssueNavigator],

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

		layout: getCommonProjectLayout(),
	}),
	createEntry(servicedeskIssuesRoute, {
		...getCommonProject(),
		...common,
		forPaint: [LazyAtlassianNavigation, LazyServiceDeskProjectIssueNavigator],
		component: ServiceDeskIssueNavigator,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServiceDesk,
		},

		resources: [
			...getCommonProjectResources(),
			queuesNavigationItems,
			getConsolidationStateResource(),
		],

		layout: serviceProjectLayout,
	}),
	createEntry(projectIssueNavigatorCoreRoute, {
		...getCommonProject(),
		...common,
		forPaint: [LazyAtlassianNavigation, LazyProjectIssueNavigator],

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalNavCore,
		},

		layout: getCommonProjectLayout(),
	}),
	createEntry(globalIssueNavigatorRoute, {
		...getCommonGlobal(),
		...common,
		ufoName: 'global-issue-navigator-jfe',
		forPaint: [LazyAtlassianNavigation, LazyGlobalIssueNavigator],
	}),
	createEntry(globalIssueNavigator1Route, {
		...getCommonGlobal(),
		...common,
		ufoName: 'global-issue-navigator-jfe',
		forPaint: [LazyAtlassianNavigation, LazyGlobalIssueNavigator],
	}),
	createEntry(globalIssueNavigatorBrowseIssueRedirectRoute, {
		...getCommonGlobal(),
		...common,
		component: GinBrowseIssuesRedirect,
		forPaint: [LazyAtlassianNavigation, LazyGlobalIssueNavigator],
	}),
];

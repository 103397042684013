import camelCase from 'lodash/camelCase';
import {
	ROUTE_NAMES_PLAYBOOKS_LIST,
	ROUTE_NAMES_PLAYBOOKS_EDIT,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	servicedeskPlaybooksListRoute,
	servicedeskPlaybooksEditRoute,
} from '@atlassian/jira-router-routes-project-settings-playbooks-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import playbookEditEntryPoint from '@atlassian/jira-spa-apps-project-settings-playbooks-edit/entrypoint';
import playbookListEntryPoint from '@atlassian/jira-spa-apps-project-settings-playbooks-list/entrypoint';
import type { Route } from '@atlassian/react-resource-router';

const listEntryPoint = createPageEntryPoint({
	main: playbookListEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const editEntryPoint = createPageEntryPoint({
	main: playbookEditEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const getServiceDeskRoutesCommon = (): Pick<Route, 'layout' | 'navigation'> => ({
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
});

export const playbooksRouteEntries = [
	createEntry(servicedeskPlaybooksListRoute, {
		...getServiceDeskRoutesCommon(),
		entryPoint() {
			return fg('playbooks-in-jsm') ? listEntryPoint : undefined;
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		meta: {
			reporting: {
				id: 'project-settings.playbooks.admin-list-view',
				packageName: camelCase(ROUTE_NAMES_PLAYBOOKS_LIST),
				teamName: 'ITOps Phantom',
			},
		},
		component: ErrorPagesNotFound,
		navigation: {
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		ufoName: 'playbook-list',
	}),
	createEntry(servicedeskPlaybooksEditRoute, {
		...getServiceDeskRoutesCommon(),
		entryPoint() {
			return fg('playbooks-in-jsm') ? editEntryPoint : undefined;
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		meta: {
			reporting: {
				id: 'project-settings.playbooks.admin-edit-view',
				packageName: camelCase(ROUTE_NAMES_PLAYBOOKS_EDIT),
				teamName: 'ITOps Phantom',
			},
		},
		component: ErrorPagesNotFound,
		navigation: {
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		ufoName: 'playbook-edit',
	}),
];

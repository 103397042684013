import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { ExperienceErrorBoundary } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { CREATE_OVERVIEW_EXPERIENCE } from '../../common/constants';
import type { Props } from './types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCreateOverviewModal = lazy(
	() => import(/* webpackChunkName: "async-overview-modal" */ './main'),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ onClose }: Props) => (
	<ExperienceErrorBoundary
		experience={CREATE_OVERVIEW_EXPERIENCE}
		fallback="unmount"
		shouldStartExperience
	>
		<Placeholder name="overview-modal" fallback={null}>
			<LazyCreateOverviewModal onClose={onClose} />
		</Placeholder>
	</ExperienceErrorBoundary>
);

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import {
	AsyncHorizontalProjectNav,
	AsyncHorizontalOverviewNav,
} from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getProjectViewResources,
	getOverviewViewResources,
} from '@atlassian/jira-router-resources-business-common';
import {
	jiraBusinessProjectRedirectRoute,
	jiraBusinessOverviewRedirectRoute,
} from '@atlassian/jira-router-routes-business-redirect-routes';
import { RedirectToLastVisitedProjectPage } from '@atlassian/jira-router-routes-common/src/ui/last-visited-project-page/index.tsx';
import OverviewRedirect from './overview-redirect';

export const jiraBusinessProjectRedirectRouteEntry = createEntry(jiraBusinessProjectRedirectRoute, {
	component: RedirectToLastVisitedProjectPage,
	layout: businessProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarCore,
		horizontal: AsyncHorizontalProjectNav,
	},
	resources: getProjectViewResources(),
	forPaint: [LazyAtlassianNavigation],
});

export const jiraBusinessOverviewRedirectRouteEntry = createEntry(
	jiraBusinessOverviewRedirectRoute,
	{
		component: OverviewRedirect,
		layout: businessProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalOverviewNav,
		},
		resources: getOverviewViewResources(),
		forPaint: [LazyAtlassianNavigation],
	},
);

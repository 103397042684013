import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { AsyncHorizontalProjectNav } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout';
import { ThemedLazySkeleton } from '@atlassian/jira-business-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import { workflowIssuesResource } from '@atlassian/jira-router-resources-business-board-workflow-issues';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common';
import { jiraBusinessBoardRoute } from '@atlassian/jira-router-routes-business-board-routes';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import BoardView, { LazyBoardView } from './board';

export const jiraBusinessBoardRouteEntry = createEntry(jiraBusinessBoardRoute, {
	component: BoardView,
	layout: composeLayouts(
		businessProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['issue-key'] }]),
	),
	skeleton: ThemedLazySkeleton,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarCore,
		horizontal: AsyncHorizontalProjectNav,
	},
	resources: [...getProjectViewResources(), workflowIssuesResource],
	forPaint: [LazyAtlassianNavigation, LazyBoardView],
	afterPaint: [LazyIssueApp],
	preloadOptions: {
		earlyChunks: ['business-board-early-entry'],
	},
});

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as BoardView } from './board';

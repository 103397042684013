import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { nextGenRoadmapResource } from '@atlassian/jira-router-resources-roadmap/src/services/next-gen-roadmap/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import { softwareTimelineRoute } from '@atlassian/jira-router-routes-software-timeline-routes';
import { RoadmapSkeleton } from '@atlassian/jira-skeletons/src/ui/roadmap/index.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout';
import type { Route } from '@atlassian/react-resource-router';
import RoadmapPage, { LazyNextGenRoadmap } from './ui/spa/roadmap';

export const softwareTimelineRouteEntry: Route = createEntry(softwareTimelineRoute, {
	component: RoadmapPage,
	skeleton: RoadmapSkeleton,

	layout: composeLayouts(
		softwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		nextGenRoadmapResource,
	],

	preloadOptions: {
		earlyChunks: ['software-roadmap-early-entry'],
	},

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyNextGenRoadmap],
	ufoName: 'next-gen-roadmap',
});

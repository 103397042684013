import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ff } from '@atlassian/jira-feature-flagging';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { jsmRepackagingBannerResource } from '@atlassian/jira-router-resources-jsm-repackaging-banner';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { servicedeskIssueTypesResource } from '@atlassian/jira-router-resources-service-desk-issue-types/src/controllers/index.tsx';
import { servicedeskPortalGroupsResource } from '@atlassian/jira-router-resources-service-desk-portal-groups/src/controllers/index.tsx';
import { servicedeskPracticesResource } from '@atlassian/jira-router-resources-service-desk-practices/src/controllers/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import {
	routeGroupsProjectSettingsServicedeskUnassignedTicketTypesConfigRoute,
	routeGroupsProjectSettingsServicedeskItsmTicketTypesConfigRoute,
	routeGroupsProjectSettingsServicedeskTicketTypesConfigRoute,
} from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import type { Route, PartialVariantRoute } from '@atlassian/react-resource-router/src/common/types';
import { getSettingsNavigationResources, SERVICE_DESK_PRODUCT } from './ui/product-routes/common';
import ServiceDeskTicketTypesConfig, {
	LazyProjectSettingsServiceDeskTicketTypesConfig,
} from './ui/service-desk-ticket-types-config';

const commonTicketTypesConfigRouteEntry: PartialVariantRoute = {
	component: ServiceDeskTicketTypesConfig,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
	},
	resources: [
		...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
		projectContextResource,
		servicedeskPracticesResource,
		servicedeskIssueTypesResource,
		servicedeskPortalGroupsResource,
		getConsolidationStateResource(),
		resourceWithCondition2(
			() => ff('enable_jsm_repackaging_changes_vtwk9'),
			jsmRepackagingBannerResource,
		),
	],
	forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskTicketTypesConfig],
	meta: {
		reporting: {
			id: APP_NAMES.REQUEST_TYPES_CONFIG,
			packageName: toPackageName(APP_NAMES.REQUEST_TYPES_CONFIG),
			teamName: 'jsd-shield',
		},
	},
	perfMetricKey: 'jsm-request-types-configuration',
	ufoName: 'jsm-request-types-configuration',
};

export const ticketTypesConfigRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsServicedeskUnassignedTicketTypesConfigRoute, {
		...commonTicketTypesConfigRouteEntry,
		component: ServiceDeskTicketTypesConfig,
	}),
	createEntry(routeGroupsProjectSettingsServicedeskItsmTicketTypesConfigRoute, {
		...commonTicketTypesConfigRouteEntry,
	}),
	createEntry(routeGroupsProjectSettingsServicedeskTicketTypesConfigRoute, {
		...commonTicketTypesConfigRouteEntry,
	}),
];

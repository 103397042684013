import { resources } from '@atlassian/eoc-api-key-management/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { eocApiKeyManagementRoute } from '@atlassian/jira-router-routes-eoc-api-key-management-routers';
import APIKeyManagementPage, { LazyApiKeyManagement } from './ui';

export const eocApiKeyManagementRouteEntry = createEntry(eocApiKeyManagementRoute, {
	component: APIKeyManagementPage,
	layout: globalSettingsLayout,

	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorziontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...resources,
	],

	forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyApiKeyManagement,
	],
});

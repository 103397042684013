import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { productsJsmConfigurationRoute } from '@atlassian/jira-router-routes-products-jsm-configuration-routes';
import ProductsJsmConfiguration, { LazyProductsJsmConfiguration } from './ui';

export const productsJsmConfigurationRouteEntry = createEntry(productsJsmConfigurationRoute, {
	ufoName: 'admin.jsm-configuration',
	component: ProductsJsmConfiguration,
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

	resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
	forPaint: [LazyAtlassianNavigation, LazyProductsJsmConfiguration],
});

import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { projectVersionsRelayResource } from '@atlassian/jira-router-resources-project-versions/src/controllers/index.tsx';
import { projectVersionsRoute } from '@atlassian/jira-router-routes-projects-versions-routes';
import type RelayProjectVersionsType from '@atlassian/jira-spa-apps-relay-project-versions';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

export const LazyRelayProjectVersions = lazyForPaint<typeof RelayProjectVersionsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-relay-project-versions", jiraSpaEntry: "async-relay-project-versions" */ '@atlassian/jira-spa-apps-relay-project-versions'
		),
	),
);

export const RelayVersions = () => (
	<LazyPage
		Page={LazyRelayProjectVersions}
		pageId="relay-project-versions"
		shouldShowSpinner={false}
	/>
);

export const projectVersionsRouteEntry: Route = createEntry(projectVersionsRoute, {
	resources: [
		...getBusinessProjectResources(),
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
		projectVersionsRelayResource,
	],

	component: RelayVersions,
	layout: genericProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},

	forPaint: [LazyAtlassianNavigation, LazyRelayProjectVersions],
});

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import componentWithConditionDeprecated from '@atlassian/jira-common-util-component-with-condition';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalMailSettingsRoute } from '@atlassian/jira-router-routes-global-mail-settings-routes';
import RedirectToLegacyRouting from './common/ui/redirect/legacy-routing';
import GlobalMailSettings, { LazyGlobalMailSettings } from './ui';

export const globalMailSettingsRouteEntry = createEntry(globalMailSettingsRoute, {
	ufoName: 'admin.global-mail-settings',

	component: componentWithConditionDeprecated(
		() => true,
		GlobalMailSettings,
		RedirectToLegacyRouting,
	),

	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsSystem,
	},

	resources: [...getNavigationResources(), navigationSidebarGlobalResource],
	forPaint: [LazyAtlassianNavigation, LazyGlobalMailSettings],
});

import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { widgetResources } from '@atlassian/eoc-who-is-on-call/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { checkShouldUseJSM } from '@atlassian/jira-eoc-tenant-info/src/controllers/utils.tsx';
import { jiraHomePageRightSidePanelResource } from '@atlassian/jira-home-right-side-panel/src/services/main.tsx';
import { homepageLayout } from '@atlassian/jira-homepage-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { CONSOLIDATION_STATE_RESOURCE_TYPE } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/constants.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import type {
	ConsolidationState,
	TenantInfo,
} from '@atlassian/jira-router-resources-eoc-tenant-info/types.tsx';
import { jiraHomeResourceWithCache } from '@atlassian/jira-router-resources-jira-home';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { jiraHomeRoute } from '@atlassian/jira-router-routes-jira-home-routes';
import { YourWorkSkeleton } from '@atlassian/jira-skeletons/src/ui/your-work/index.tsx';
import type JiraHomeType from '@atlassian/jira-spa-apps-jira-home';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { RouteResourceResponse } from '@atlassian/react-resource-router';

const LazyJiraHomePage = lazyForPaint<typeof JiraHomeType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-jira-home", jiraSpaEntry: "async-jira-home" */ '@atlassian/jira-spa-apps-jira-home'
		),
	),
);

// Redefined here since it is not exported from RRR.
type ResourceDependencies = {
	[type: string]: RouteResourceResponse;
};

const checkConsolidationOnRouteDependency = async (dependencies: ResourceDependencies) => {
	const data = await dependencies[CONSOLIDATION_STATE_RESOURCE_TYPE].promise;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return checkShouldUseJSM(data as ConsolidationState | TenantInfo | null);
};

export const JiraHomeComponent = () => (
	<LazyPage Page={LazyJiraHomePage} pageId="jira-home" shouldShowSpinner={false} />
);

export const jiraHomeRouteEntry = createEntry(jiraHomeRoute, {
	ufoName: 'jira-home',
	component: JiraHomeComponent,
	skeleton: YourWorkSkeleton,
	layout: homepageLayout,
	navigation: {
		menuId: MENU_ID.HOME,
	},
	resources: [
		...getNavigationResources(),
		jiraHomeResourceWithCache,
		jiraHomePageRightSidePanelResource,
		getConsolidationStateResource(),
		...widgetResources.map((resourceCreator) =>
			resourceCreator(checkConsolidationOnRouteDependency, [CONSOLIDATION_STATE_RESOURCE_TYPE]),
		),
	],
	forPaint: [LazyAtlassianNavigation, LazyJiraHomePage],
});

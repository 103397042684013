import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { jiraSearchRoute } from '@atlassian/jira-router-routes-jira-search-routes';
import type { Route } from '@atlassian/react-resource-router';
import { JiraSearchRedirect } from './ui/redirect';
import { jiraSearchResource } from './ui/redirect/resources';

export const jiraSearchRouteEntry: Route = createEntry(jiraSearchRoute, {
	component: JiraSearchRedirect,
	isRedirect: true,
	layout: globalLayout,
	resources: [...getNavigationResources(), jiraSearchResource],
	forPaint: [LazyAtlassianNavigation],
});

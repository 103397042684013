import isEqual from 'lodash/isEqual';
import isMobileUserAgent from '@atlassian/jira-common-is-mobile-user-agent';
import type { MatchedRoute } from '@atlassian/react-resource-router';
/**
 * Determines the feasibility of transitioning between routes in a SPA, considering initial loads,
 * user agent constraints, and predefined route conditions. Ensures seamless navigation by
 * evaluating route matches and permissions.
 */
const transitionValidator = (currentMatch: MatchedRoute | null, nextMatch: MatchedRoute | null) => {
	// if we load SPA on a redirect route, we transition immediately skipping the checks
	if (!currentMatch) return true;
	// if next route missing (should not happen as we have a catch all route) or mobile, trigger reload
	if (!nextMatch || isMobileUserAgent()) return false;

	// If we are still on same route and same match params, skip transition checks
	const isSameRoute = currentMatch.route === nextMatch.route;
	const isSameMatch = isEqual(currentMatch.match, nextMatch.match);
	if (isSameRoute && isSameMatch) return true;

	// check we can transition into next route
	// @ts-expect-error - TS2339 - Property 'canTransitionIn' does not exist on type 'Route'.
	const canDoNext = nextMatch.route.canTransitionIn?.(currentMatch, nextMatch) ?? true;
	if (canDoNext) {
		return true;
	}
	return false;
};
export default transitionValidator;

import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { servicedeskProjectSummaryRoute } from '@atlassian/jira-router-routes-servicedesk-project-summary-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { ClassicCalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-calendar/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { projectSummaryPageEntryPoint } from './ui';

export const projectSummaryRouteEntries: Route[] = [
	createEntry(servicedeskProjectSummaryRoute, {
		ufoName: 'jsm.project-summary',
		component: componentWithCondition(
			() => fg('jsm-project-summary'),
			() => null,
			ErrorPagesNotFound,
		),
		// TODO - add skeleton
		skeleton: ClassicCalendarSkeleton,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		layout: serviceProjectLayout,

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

		entryPoint: () => (fg('jsm-project-summary') ? projectSummaryPageEntryPoint : undefined),

		meta: {
			reporting: {
				id: 'project-summary',
				packageName: toPackageName(APP_NAMES.PROJECT_SUMMARY),
				teamName: 'jsd-shield',
			},
		},
	}),
];

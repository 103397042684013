import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	shouldLoadUIFBoardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
	getUserBoardNavigationResources,
} from '@atlassian/jira-router-resources-navigation';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import { userBoardRoute } from '@atlassian/jira-router-routes-user-board-routes';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/index.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import {
	RapidBoardSpaPage,
	LazyRapidBoard,
	LazyRapidBoardApps,
	LazyRapidBoardDataApiResource,
	LazyRapidBoardConfigApiResource,
	LazyRapidBoardWrmBundleResource,
	LazyRapidBoardResource,
} from './async';

/**
 * Jira Software user-located boards SPA route
 */
export const userBoardRouteEntry = createEntry(userBoardRoute, {
	component: RapidBoardSpaPage,
	skeleton: ClassicBoardSkeleton,
	layout: composeLayouts(
		genericProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{ query: ['selectedIssue', 'view=detail'] },
			{ query: ['selectedIssue', 'modal=detail'] },
		]),
	),
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: classicBoardSidebars,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		...getUserBoardNavigationResources(),
		resourceWithCondition2(shouldLoadUIFBoardResources, uifBoardResource),
		getEditModelResource(),
		getBoardDetailsResource(),
	],
	afterPaint: [LazyIssueApp],
	forPaint: [
		LazyAtlassianNavigation,
		LazyRapidBoard,
		LazyRapidBoardApps,
		LazyRapidBoardDataApiResource,
		LazyRapidBoardConfigApiResource,
		LazyRapidBoardWrmBundleResource,
		LazyRapidBoardResource,
	],
});

import React, { memo, useCallback } from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { OpenDrawerMethod } from '@atlassian/jira-project-create-drawer/src/types.tsx';
import type { ProjectTemplatesDataType } from '../../../../services/project-templates/types';
import OneClickProjectButton from './main';
import type TemplatePickerType from './template-picker';
import { TemplatePickerFallback } from './template-picker';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyTemplatePicker = lazy<typeof TemplatePickerType>(
	() => import(/* webpackChunkName: "async-sidebar-template-picker" */ './template-picker'),
	{ ssr: false },
);

type Props = {
	openProjectCreateDrawer: OpenDrawerMethod;
};

const OneClickProject = ({ openProjectCreateDrawer }: Props) => {
	const renderContent = useCallback(
		(data: ProjectTemplatesDataType, loading: boolean) => (
			<Placeholder name="sidebar-template-picker" fallback={<TemplatePickerFallback />}>
				<LazyTemplatePicker
					data={data}
					loading={loading}
					openProjectCreateDrawer={openProjectCreateDrawer}
				/>
			</Placeholder>
		),
		[openProjectCreateDrawer],
	);

	return (
		<OneClickProjectButton
			renderContent={renderContent}
			openProjectCreateDrawer={openProjectCreateDrawer}
		/>
	);
};

export default memo<Props>(OneClickProject);

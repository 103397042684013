import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsSystemUiAnnouncementBannerRoute } from '@atlassian/jira-router-routes-admin-pages-system-routes';
import type AppType from '@atlassian/jira-spa-apps-admin-pages-system-ui-announcement-banner';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

export const LazyAnnouncementBanner = lazyForPaint<typeof AppType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-admin-pages-system-ui-announcement-banner", jiraSpaEntry: "async-admin-pages-system-ui-announcement-banner" */ '@atlassian/jira-spa-apps-admin-pages-system-ui-announcement-banner'
		),
	),
);

export const AnnouncementBanner = () => (
	<LazyPage
		Page={LazyAnnouncementBanner}
		pageId="admin-pages-system-ui-announcement-banner"
		shouldShowSpinner
	/>
);

export const systemRouteEntries: Route[] = [
	createEntry(globalSettingsSystemUiAnnouncementBannerRoute, {
		ufoName: 'global-admin.system.ui.announcement-banner',
		component: AnnouncementBanner,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyAnnouncementBanner],
	}),
];

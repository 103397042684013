import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { projectNotFoundRoute } from '@atlassian/jira-router-routes-project-not-found-routes';
import { projectNotFoundEntryPoint } from '@atlassian/jira-spa-apps-project-not-found/entrypoint';

export const projectNotFoundPageEntryPoint = createPageEntryPoint({
	main: projectNotFoundEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const projectNotFoundRouteEntry = createEntry(projectNotFoundRoute, {
	entryPoint() {
		return projectNotFoundPageEntryPoint;
	},
	layout: globalLayout,
	resources: [...getNavigationResources()],
	pageId: 'project-not-found-page-id',
});

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	AsyncJSMOperationsHorizontalNav,
	LazyJSMOperationsHorizontalNav,
} from '@atlassian/jira-horizontal-nav-jsm-operations/src';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { jsmRepackagingBannerResource } from '@atlassian/jira-router-resources-jsm-repackaging-banner';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { servicedeskChangeCalendarRoute } from '@atlassian/jira-router-routes-servicedesk-change-calendar-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import type { Route } from '@atlassian/react-resource-router';
import ChangeCalendar, { LazyChangeCalendar } from './ui/calendar';

export const changeCalendarRouteEntries: Route[] = [
	createEntry(servicedeskChangeCalendarRoute, {
		ufoName: 'service-management.change-calendar',
		component: ChangeCalendar,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncJSMOperationsHorizontalNav,
			onlyShowHorziontalOnNav4: true,
		},

		layout: serviceProjectLayout,

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
			resourceWithCondition2(
				() => ff('enable_jsm_repackaging_changes_vtwk9'),
				jsmRepackagingBannerResource,
			),
		],

		forPaint: [LazyAtlassianNavigation, LazyChangeCalendar, LazyJSMOperationsHorizontalNav],

		meta: {
			reporting: {
				id: 'changeCalendar',
				packageName: toPackageName(APP_NAMES.CHANGE_CALENDAR),
				teamName: 'jenga',
			},
		},
	}),
];

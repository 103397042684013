/* eslint-disable jira/import/webpack-magic-comment-entries */
import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type OverviewNavigation from './ui/overview-navigation/index.tsx';
import type ProjectNavigation from './ui/project-navigation/index.tsx';

const LazyHorizontalProjectNav = lazyForPaint<typeof ProjectNavigation>(
	() =>
		import(
			/* webpackChunkName: "async-business-horizontal-nav" */ './ui/project-navigation/index.tsx'
		),
);

export const AsyncHorizontalProjectNav = () => (
	<Placeholder name="horizontal-project-nav" fallback={null}>
		<LazyHorizontalProjectNav />
	</Placeholder>
);

const LazyHorizontalOverviewNav = lazyForPaint<typeof OverviewNavigation>(
	() =>
		import(
			/* webpackChunkName: "async-business-horizontal-nav" */ './ui/overview-navigation/index.tsx'
		),
);

export const AsyncHorizontalOverviewNav = () => (
	<Placeholder name="horizontal-overview-nav" fallback={null}>
		<LazyHorizontalOverviewNav />
	</Placeholder>
);
